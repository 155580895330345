import React from "react"
import "../components/styles.css"
import Navbar from "../components/Navbar"
import Sidemenu from "../components/Sidemenu"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../components/Header"

const About = () => {
  const data = useStaticQuery(
    graphql`
      query {
        info: markdownRemark(frontmatter: { title: { eq: "About" } }) {
          frontmatter {
            title
            heading
            description {
              desc
              subdesc
              feature1
              feature2
              feature3
              feature4
            }
          }
        }
      }
    `
  )
  // Set ImageData.
  return (
    <>
      <SEO title="About" />
      <Header />
      <section className="container-fluid about position-relative">
        <Sidemenu />

        <div className="container">
          <Navbar />

          <div className="row about-row mx-0">
            <div className="d-flex flex-column col-md-5 col-sm-12 col-lg-4 col-xl-4 p-0 m-0"></div>
            <div className="d-flex flex-column text-white col-sm-12 col-md-7 col-lg-7 col-xl-6 justify-content-center pl-xl-5">
              <h1 className="title mt-0 mb-4 pr-lg-5 pr-xl-0 text-uppercase">
                {" "}
                SUSTAINABLE, TECHNICALLY <br />
                ADVANCED AND INNOVATIVE <br />
                CHEMICAL PRODUCTS
              </h1>
              <hr className="hr-line" />
              <div className="col-lg-9 col-xl-11 p-0 w-100 mt-4">
                <div
                  data-sal="slide-left"
                  data-sal-duration="2000"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                >
                  <p className="content mt-1 ">
                    {data.info.frontmatter.description.desc}
                  </p>
                  <p>{data.info.frontmatter.description.subdesc}</p>
                  <p>{data.info.frontmatter.description.feature1}</p>

                  <p className="py-2">
                    {data.info.frontmatter.description.feature2}
                  </p>
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-duration="2000"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                >
                  <hr className="hr-line pt-2" />
                  <p className=" highlight font-weight-bold  py-2">
                    {data.info.frontmatter.description.feature3}
                  </p>
                  <p>{data.info.frontmatter.description.feature4}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-about pb-2">
          <Footer />
        </div>
      </section>
    </>
  )
}

export default About
