import React from "react"
// import Img from "gatsby-image"
import Icons from "./Icons"
import "./styles.css"

const Certificate = ({ className }) => {
  return (
    <div className="container pt-0">
      <div className="row ">
        <div className="footer d-flex flex-column text-white col-12 col-md-8 pr-5">
          <small className="footer-note">
            All Rights Reserved Icon Maritime Technology &amp; Consulting LLC
          </small>
        </div>
        <Icons className="icons" />
      </div>
    </div>
  )
}

export default Certificate
